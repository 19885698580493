import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'
import moment from 'moment'
import siteinfo from '@/util/global'

class Card{

    /**
     * 获取购物卡
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCard(page,limit,extra,fn){
        let param = { page,limit}

        if( extra.fettle ) param.fettle = extra.fettle
        if( extra.select_type ==1 ) param.user_id = extra.key
        if( extra.select_type ==2 ) param.user_info = extra.key
        if( extra.select_type ==3 ) param.numbers = extra.key
        if( extra.time.length>0 ){
            param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
            param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
        }
        common.getDataList("getShoppingCardList",param).then(res=>fn(res))
    }

    /**
     * 添加购物卡
     * @param {object} param 
     */
    addCard(param){
        if( !tool.returnMessage(param.title,"请填写礼品卡标题")) return
        if( !tool.returnMessage(param.price,"请填写礼品卡面值")) return
        if( !tool.returnMessage(param.summary,"请填写礼品卡描述")) return
        if( !param.cid ) delete param.cid
        param.start_time = moment(param.start_time).format("YYYY-MM-DD HH:mm:ss")
        param.end_time = moment(param.end_time).format("YYYY-MM-DD HH:mm:ss")
        $post("createOrUpdateCard",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 导出购物卡
     * @param {object} extra 
     */
    exportCard(extra){
        let url = siteinfo.siteinfo.siteroot+'/api/admin/ShopCardExport?token='+localStorage.getItem('token');
        if( extra.fettle ) url+='&fettle='+search.fettle
        if( extra.user_id ) url+='&user_id='+search.user_id
        if( extra.time.length>0 ){
            url+= '&start_time='+moment(extra.time[0]).format("YYYY-MM-DD")
            url+= '&end_time='+moment(extra.time[1]).format("YYYY-MM-DD")
        }
        window.open(url);
    }

    /**
     * 获取购物卡包装信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getCardPackage(page,limit,fn){
        let param = {page,limit}
        common.getDataList("getPackAgeList",param).then(res=>fn(res))
    }
    /**
     * 新增或编辑包装信息
     * @param {number} param 
     * @param {number} fn 
     */
    addOrEditPackage(param,fn){
        if( !tool.returnMessage(param.name,"请输入包装名称")) return
        if( !param.id ) delete param.id
        $post("createOrUpdateCardPackage",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }
}
const cardModel = new Card()
export default cardModel